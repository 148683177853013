.square2 {
  width: 80px;
  height: 80px;
  display: flex;
  flex-wrap: wrap;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.square2 > span {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 30px;
  height: 30px;
  border-radius: 20%;
  background-color: #e94d4d;
  margin: 5px;
  transition: 0.2s;
  animation: square 2.4s linear infinite;
}
.square2 > span:nth-last-child(2) {
  background-color: rgb(236, 102, 102);
  animation-delay: 0.8s;
}
.square2 > span:nth-last-child(3) {
  background-color: rgb(233, 147, 147);
  animation-delay: 1.6s;
}

@keyframes square {
  0% {
    left: 0;
    top: 0;
  }
  10% {
    left: 40px;
    top: 0;
  }
  25% {
    left: 40px;
    top: 0;
  }
  35% {
    left: 40px;
    top: 40px;
  }
  50% {
    left: 40px;
    top: 40px;
  }
  60% {
    left: 0;
    top: 40px;
  }
  75% {
    left: 0;
    top: 40px;
  }
  85% {
    left: 0;
    top: 0;
  }
  100% {
    left: 0;
    top: 0;
  }
}
